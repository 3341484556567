<template>
  <div class="vaski">
    <img class="vaski-img" src="@/assets/images/bgnmyd4u.jpg" alt="" />
    <div class="vaski-bg">
      <div class="vaski-tap">
        <div class="vaski-left">
          <span class="text">走进天空</span>
          <el-icon class="icon" color="#666666">
            <ArrowRight />
          </el-icon>
          <span class="text">{{ name[tabIndex] }}</span>
        </div>
        <div class="demo-tabs">
          <div class="tabs-name-bar" @click="clickTab(0)">企业概况</div>
          <div class="tabs-name-bar" @click="clickTab(1)">企业文化</div>
          <div class="tabs-name-bar" @click="clickTab(2)">企业架构</div>
          <div class="tabs-name-bar" @click="clickTab(3)">主要团队</div>
          <div class="tabs-name-bar" @click="clickTab(4)">荣誉历程</div>
          <div class="tabs-name-bar" @click="clickTab(5)">合作伙伴</div>
          <div class="tab-bottm" :style="`left:${left}px`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabInfo",
  components: {},
  props: {
    tabIndex: Number,
  },
  data() {
    return {
      left: 0,
      name:[
        '企业概况',
        '企业文化',
        '企业架构',
        '主要团队',
        '荣誉历程',
        '合作伙伴',
      ]
    };
  },
  methods: {
    clickTab(index) {
      const url = {
        0: "/vaski-situation",
        1: "/vaski-culture",
        2:'/vaski-structure',
        3:'/vaski-member',
        4:'/vaski-experience',
        5:'/vaski-cooperation'
      };
      this.$router.push(url[index]);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    getDome() {
      const doms = document.getElementsByClassName("tabs-name-bar")[this.tabIndex];
      this.left = doms.offsetLeft;
    },
  },
  mounted() {
    this.getDome();
  },
};
</script>

<style lang="scss" scoped>
.vaski {
  width: 100%;
  overflow: hidden;
  cursor: default;

  .vaski-img {
    width: 100%;
  }

  .vaski-bg {
    width: 100%;
    border-bottom: solid 2px #f3f3f3;

    .vaski-tap {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #f3f3f3;

      .vaski-left {
        font-size: 15px;
        display: flex;
        align-items: center;

        .text {
          color: #7C7C7C;
        }

        .icon {
          margin: 0 10px;
        }
      }

      .demo-tabs {
        height: 100%;
        display: flex;
        position: relative;
        color: #7C7C7C;

        .tabs-name-bar {
          font-size: 20px;
          width: 130px;
          line-height: 80px;
          text-align: center;
          font-size: 15px;
          cursor: default;
        }

        .tab-bottm {
          width: 130px;
          height: 0%;
          border-bottom: solid 4px #296ea2;
          position: absolute;
          bottom: -3px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .vaski {
    .vaski-img {
      width: 250%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    .vaski-bg {
      width: 100%;
      border-bottom: solid 3px rgba(209, 209, 209, 0.3137254902);

      .vaski-tap {
        width: 100%;
        margin: 0 auto;
        display: block;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #f3f3f3;

        .vaski-left {
          font-size: 16px;
          display: flex;
          margin-left: 20px;
          height: 40px;

          .text {
            color: #666;
          }

          .icon {
            margin: 0 10px;
          }
        }

        .demo-tabs {
          height: 100%;
          display: flex;
          position: relative;
          color: #7C7C7C;
          justify-content: space-between;

          .tabs-name-bar {
            font-size: 20px;
            width: 60px;
            line-height: 50px;
            text-align: center;
            font-size: 12px;
            cursor: default;
          }

          .tab-bottm {
            width: 60px;
            height: 0%;
            border-bottom: solid 4px #296ea2;
            position: absolute;
            bottom: -3px;
          }
        }
      }
    }
  }
}
</style>
