<template>
    <div id="experIence-lit" class="experIence-lit">
        <!-- <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">集团现状</div>
                </div>
                <div class="right">
                    <div class="right-name">聚焦核心主业，回归本源发展</div>
                    <div class="right-text">
                        今天的天空集团，资产规模快速增长，盈利能力显著提升，管理水平逐步提高，社会贡献不断加大，目前集团在职人数1200余人，管理层200余人，在江苏省内外拥有江苏天空城市服务有限公司、江苏天空保安有限公司、江苏天空智慧城市运营有限公司、江苏天空市政建设工程有限公司、江苏天空电梯科技有限公司、常州天空拆迁有限公司、常州元一文化传播有限公司、西藏威斯凯酒店管理有限公司、天空未来城市（常州）研究院有限公司、天空网络运营（常州）有限公司等优质子公司。

                    </div>
                    <div class="right-text">
                        旗下城市服务、实业两大业务板块经营业绩稳中向好。企业顺应发展需求，聚焦核心主业，回归本源发展，经营质量不断提升。天空城服突出城市管理和城市运营特色，整体经营稳中向好；企业聚焦各自主业，推进城市转型发展。
                    </div>
                    <div class="right-text">
                        实业企业拥抱行业变革，加快市场拓展，不断延伸产业布局，服务民生能力大幅增强。智慧城市板块项目亮点纷呈，市场优势地位不断巩固；电梯设备板块积极探索新业态，转型升级持续推进；酒店管理板块产品体系不断健全，高质量发展稳步实现；文化传播板块赋能集团信息化建设，自主创新能力持续增强。未来城市研究院给予集团未来前进的方向和思路，在行业内大放异彩。天空网络运营通过顺应当下时代潮流，为集团的发展拓宽道路。招商引资板块不断的给各经济开发区输送优质高质企业，不断地给当地带来亮眼的经济效益。

                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div> -->
        <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">
                        2024<br />
                        -<br />
                    </div>
                </div>
                <div class="right">
                    <div class="right-name"></div>
                    <div class="right-text">
                        开启六大板块战略，专业化推进“城市服务、资产股权、市政工程、乐园酒店、品牌传媒、数字信息”等板块工作。
                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">
                        2021<br />
                        -<br />
                    </div>
                </div>
                <div class="right">
                    <div class="right-name"></div>
                    <div class="right-text">
                        历经三年筹备，集团迁入新总部大楼“天空中心”，迅速扩大团队规模。
                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">
                        2018<br />
                        -<br />
                    </div>
                </div>
                <div class="right">
                    <div class="right-name"></div>
                    <div class="right-text">
                    “天空物业”正式升级为“天空城市服务”，融合物业服务、环卫服务、安保安防、电梯工程、停车运营等业态，逐步发展为综合性城市运营类企业集团。公司在管面积突破600万㎡，业务范围辐射至西藏等多个省区。
                </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">
                        2017<br />
                        -<br />
                    </div>
                </div>
                <div class="right">
                    <div class="right-name"></div>
                    <div class="right-text">
                        “恩博国际贸易”正式更名为“天空控股集团”，下辖多个行业专业主体，开启集团战略发展新阶段。
                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">
                        2014<br />
                        -<br />
                    </div>
                </div>
                <div class="right">
                    <div class="right-name"></div>
                    <div class="right-text">
                        “天空城市服务”前身“天空物业”于常州市武进区创立，集团开始涉足物业环卫等业务。
                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">
                        2012<br />
                        -<br />
                    </div>
                </div>
                <div class="right">
                    <div class="right-name"></div>
                    <div class="right-text">
                        “刘鲸”带领团队创立“博美新材料”品牌，进入光电缆护套材料制造行业。企业于2年内成长为行业知名品牌，产销规模一度跃居华东地区行业第二，且成功获批科技型民营企业，科技型中小企业等称号。  </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="value">
            <div class="date">
                <div class="left">
                    <div class="left-name">
                        2010<br />
                        -<br />
                        
                    </div>
                </div>
                <div class="right">
                    <div class="right-name"></div>
                    <div class="right-text">
                        “天空控股集团”前身“恩博国际贸易”在常州世贸中心成立，创始人“刘鲸”带领初始团队，稳步发展国内外商品贸易业务，并取得初步经营成果。
                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="value">
            <div class="date">
                <div class="left">

                </div>
                <div class="right">

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'ExperIence',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/*引用字库*/
@font-face {
    font-family: "MontserratMedium";
    src: url("../../assets/font/Montserrat-Medium.otf");
}

@keyframes example1 {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

.experIence-lit {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 60px 0;
    padding-bottom: 0;
    background: url('../../assets/images/member-bg.png') no-repeat;
    background-position: 0px 200px;

    .value {
        width: 100%;
        height: auto;

        &:first-child {
            // background: #f9f9f9;

        }

        .date {
            width: 1400px;
            margin: 0 auto;
            display: flex;


            .left {
                flex: 2;
                position: relative;
                right: -2px;
                z-index: 9;
                box-sizing: border-box;
                padding-top: 40px;
                animation: example1 1s ease-out 0s backwards;

                .left-name {
                    width: auto;
                    text-align: end;
                    box-sizing: border-box;
                    padding-right: 50px;
                    font-size: 25px;
                    font-weight: 500;
                    color: #286ea2;
                    border-right: solid 2px #286ea2;
                    position: absolute;
                    right: 0;
                    top: 40px;
                    text-align: center;
                    font-family: MontserratMedium;
                }
            }

            .right {
                flex: 4;
                border-left: solid 2px #c4c4c4bb;
                box-sizing: border-box;
                padding-left: 50px;
                color: #7c7c7c;
                position: relative;
                z-index: 8;
                box-sizing: border-box;
                padding-top: 40px;
                padding-bottom: 40px;
                animation: example1 1s ease-out 0s backwards;

                .right-name {
                    font-size: 25px;
                    line-height: 30px;
                    margin-bottom: 20px;
                }

                .right-text {
                    font-size: 15px;
                    margin-bottom: 30px;
                }

                .border {
                    width: 95%;
                    height: 0;
                    border-bottom: dashed 1px #7c7c7c;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

}

@media (max-width: 767px) {
    .experIence-lit {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 60px 0;
        padding-bottom: 0;

        .value {
            width: 100%;
            height: auto;
            box-sizing: border-box;
            padding-right: 20px;
            &:first-child {
                // background: #f3f3f3;

            }

            .date {
                width: 100%;
                margin: 0 auto;
                display: flex;


                .left {
                    flex: 2;
                    position: relative;
                    right: -2px;
                    z-index: 9;
                    box-sizing: border-box;
                    padding-top: 10px;

                    .left-name {
                        width: auto;
                        text-align: end;
                        box-sizing: border-box;
                        padding-right: 10px;
                        font-size: 16px;
                        color: #286ea2;
                        border-right: solid 2px #286ea2;
                        position: absolute;
                        right: 0;
                        top: 40px;
                        text-align: center;
                        font-family: MontserratMedium;

                    }
                }

                .right {
                    flex: 6;
                    border-left: solid 2px #c4c4c4bb;
                    box-sizing: border-box;
                    padding-left: 10px;
                    color: #7c7c7c;
                    position: relative;
                    z-index: 8;
                    box-sizing: border-box;
                    padding-top: 20px;
                    padding-bottom: 20px;

                    .right-name {
                        font-size: 18px;
                        line-height: 30px;
                        margin-bottom: 10px;
                    }

                    .right-text {
                        font-size: 12px;
                        margin-bottom: 10px;
                    }

                    .border {
                        width: 95%;
                        height: 0;
                        border-bottom: dashed 1px #7c7c7c;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }



    }


}
</style>