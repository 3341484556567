<template>
    <div class="tree-node">
        <!-- <div class="nodeName">{{ rootNodeName }}</div> -->
                        <img  class="children"  src="https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/organization.png" alt="">
        <!-- <div class="children" :style="childrenLineStyle">
            <div v-for="(item, index) in childNodes" :key="index" :class="['child-border']">
                <div class="name">{{ item.name }}</div>
                <div v-if="index === 0" class="flex-list">
                    <div class="value" v-for="e in item.list" :key="e">{{ e.name }}</div>
                </div>
                <div v-if="index === 1" class="flex-list">
                    <div class="child-node" v-for="node in item.list" :key="node.id">
                        <div class="node">
                            <div class="center">
                                {{ node.name }}
                            </div>
                        </div>
                        <div class="node-children">
                            <div class="text" v-for="item in node.list" :key="item">{{ item }}</div>
                            <div class="btn-name">{{ node.value }}</div>
                        </div>

                    </div>
                </div>
            </div>

        </div> -->

    </div>
</template>
<script>
export default {
    name: 'SwiperInfo',
    components: {
    },
    props: {

    },
    data() {
        return {
            childNode: null,
            childrenWidth: null,
            childrenMarginLeft: null,
            rootNodeName: '天空控股集团有限公司',
            childNodes: [
                {
                    name: '集团总部',
                    list: [
                        { id: 1, name: '品牌中心' },
                        { id: 2, name: '行政中心' },
                        { id: 3, name: '人力资源中心' },
                        { id: 4, name: '财务中心' },
                        { id: 5, name: '法务中心' },
                        { id: 6, name: '纪检委员会' }]
                },
                {
                    name: '六大板块',
                    list: [
                        { id: 4, name: '天空城市服务有限公司', value: '城市服务', list: ['天空保安服务（江苏）有限公司', '天空电梯工程（江苏）有限公司', '天空智慧物联（常州）有限公司', '天空环境工程（江苏）有限公司', '天联物业服务（常州）有限公司', '天驰物业服务（常州）有限公司'] },
                        { id: 3, name: '天空城市发展（江苏）有限公司', value: '城市发展', list: ['天空能源（北京）有限公司', '天空拆迁（常州）有限公司','天空供应链（常州）有限公司'] },
                        { id: 6, name: '天空市政工程（常州）有限公司', value: '市政工程', list: ['天空建设工程（常州）有限公司', '天空建设工程（杭州）有限公司'] },
                        { id: 5, name: '天空文旅发展（常州）有限公司', value: '文旅发展', list: ['常州元一文化传播有限公司', '威斯凯酒店（西藏）有限公司', '天空游乐发展（常州）有限公司','天空酒店发展（常州）有限公司'] },
                        { id: 1, name: '天空传媒发展（溧阳）有限公司', value: '传媒发展', list: ['青雷传媒（北京）有限公司','天空电子商务（常州）有限公司'] },
                        { id: 2, name: '天空信息技术（北京）有限公司', value: '信息技术', list: ['天空智能科技（常州）有限公司', '天空信息安全（江苏）有限公司', '天空未来城市（常州）研究院有限公司'] },
                    ]
                }
            ]
        }
    },
    methods: {
        childrenLineStyle() {
            if (!this.childNode) {
                return {};
            }

            const getWidth = (element) => {
                const style = getComputedStyle(element);
                return parseFloat(style.width) + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
            };

            let allNodeWidth = Array.from(this.childNode).reduce((total, node) => total + getWidth(node), 0);
            const firstNodeWidth = getWidth(this.childNode[0]) / 2;
            let lastNodeWidth = 0;
            if (this.childNode.length > 1) {
                lastNodeWidth = getWidth(this.childNode[this.childNode.length - 1]) / 2;
            }
            this.childrenWidth = `${allNodeWidth - firstNodeWidth - lastNodeWidth}px`;
            this.childrenMarginLeft = `${firstNodeWidth}px`;
            return {};
        }
    },
    mounted() {
        this.childNode = document.querySelectorAll('.child-node');
    },
}
</script>


<style lang="scss" scoped>
/*引用字库*/
@font-face {
    font-family: "SourceHanSansCN";
    src: url("../../assets/font/SourceHanSansCN-Normal.otf");
}

/*引用字库*/
@font-face {
    font-family: "MontserratMedium";
    src: url("../../assets/font/Montserrat-Medium.otf");
}

.tree-node {
    width: 1400px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 100px;
    animation: example1 1s ease-out 0s backwards;

}

.nodeName {
    width: 847px;
    font-size: 40px;
    line-height: 180px;
    box-sizing: border-box;
    padding-top: 100px;
    background: url(@/assets/images/wial1vab.png) no-repeat;
    background-size: 100%;
    background-position: 0 30px;
    text-align: center;
    color: #005284;
    font-family: MontserratMedium;
}

.node {
    padding: 5px;
    position: relative;
    width: 180px;
    height: 74px;
    background-color: #276999;
    border-radius: 10px;
    padding: 10px 10px;
    color: #fff;
    box-sizing: border-box;
    font-family: SourceHanSansCN;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
   .center{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
   }
}

.node-children {
    height: 574px;
    width: 180px;
    margin-top: 10px;
    background-color: #f3f3f3;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;

    .text {
        display: inline-block;
        writing-mode: vertical-rl;
        width: 46px;
        font-size: 16px;
        line-height: 16px;
        color: #276999;
        margin-bottom: 40px;
        line-height: 40px;
    }

    .btn-name {
        font-size: 15px;
        color: #000;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%)
    }
}

.children {
    width: 1400px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 90px;

    .child-border {
        height: 732px;
        border-radius: 10px;
        border: dashed 2px #c7c7c7;
        position: relative;
        top: 44px;
        box-sizing: border-box;
        padding: 50px 25px 0 30px;

        &:nth-child(1) {
            margin-right: 43px;
        }

        .name {
            color: #276999;
            background-color: #fff;
            font-size: 22px;
            font-weight: 500;
            box-sizing: border-box;
            padding: 0 10px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -15px;

            &::before {
                content: '';
                width: 0;
                height: 50px;
                border-right: solid 1px #000;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -50px;
            }

        }

        .flex-list {
            display: flex;

            .value {
                width: 33px;
                height: 174px;
                border-radius: 10px;
                background-color: #276999;
                color: #fff;
                font-family: SourceHanSansCN;
                font-size: 18px;
                line-height: 20px;
                text-align: center;
                box-sizing: border-box;
                padding-top: 20px;
                margin-right: 5px;
                font-weight: 600;
            }
        }
    }

}

@keyframes example1 {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 50%;
        opacity: 1;
    }
}


.child-node {

    position: relative;
    margin-left: 10px;
    margin-right: 10px;

}





@media (max-width: 767px) {
    .tree-node {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 100px;
    }

    .nodeName {
        width: 80%;
        font-size: 20px;
        line-height: 100px;
        box-sizing: border-box;
        padding-top: 20px;
        background: url(@/assets/images/wial1vab.png) no-repeat;
        background-size: 100%;
        background-position: 0 30px;
        text-align: center;
    }

    .node {
        padding: 5px;
        position: relative;
        width: 200px;
        height: 80px;
        background-color: #276999;
        border-radius: 10px;
        padding: 10px 10px;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        box-sizing: border-box;

    }

    .node-children {
        width: 200px;
        height: 500;
        margin-top: 10px;
        background-color: #f3f3f3;
        box-sizing: border-box;
        padding: 30px 20px;
        display: flex;
        flex-wrap: wrap;

        .text {
            display: inline-block;
            writing-mode: vertical-rl;
            width: 46px;
            font-size: 16px;
            line-height: 16px;
            color: #276999;
            margin-bottom: 40px;
            line-height: 40px;
        }

        .btn-name {
            font-size: 25px;
            color: #000;
            width: 100%;
            text-align: center;
            margin-top: 30px;
        }
    }

    .children {
        width: 100%;
        display: flex;
        justify-content: left;
        position: relative;
        margin-top: 20px;
        overflow-x: auto;
        box-sizing: border-box;
    }

  

    .child-node {

        position: relative;
        margin-left: 10px;
        margin-right: 10px;

    }


 
}
</style>