<template>
    <header class="header-area " :style="`background: rgba(255, 255, 255,${top});`">
        <div class="header-three-area">
            <div class="logo">
                <img v-show=" top===0" src="../assets/logo-light.png" />
                <img v-show=" top!==0" src="../assets/logo-light-x.png" />
            </div>
            <div class="demo-tabs" :style="`color:rgb(${color}, ${color}, ${color})`">
                <div class="tabs-name" @click="clickTab(0)">首页</div>
                <div class="tabs-name" @click="clickTab(1)">走进天空</div>
                <div class="tabs-name" @click="clickTab(2)">业务板块</div>
                <div class="tabs-name" @click="clickTab(3)">新闻中心</div>
                <div class="tabs-name" @click="clickTab(4)">联系我们</div>
                <div class="tab-bottm" :style="`left:${left}px`"></div>
            </div>
            <div class="header-icon" @click="show = true">
                <el-icon class="icon" size="20" :color="`rgb(${color}, ${color}, ${color})`">
                    <Fold />
                </el-icon>
             
            </div>
            <div class="tabs-drawer" v-if="show"  @click.stop="show = false">
                <div class="drawer">
                    <div class="drawer-logo">
                    <img src="../assets/logo-light-x.png" />
                </div>
                <div class="tabs-name" @click="clickTab(0)">首页</div>
                <div class="tabs-name" @click="clickTab(1)">走进天空</div>
                <div class="tabs-name" @click="clickTab(2)">业务板块</div>
                <div class="tabs-name" @click="clickTab(3)">新闻中心</div>
                <div class="tabs-name" @click="clickTab(4)">联系我们</div>
                <div class="tab-bottm" :style="`left:${left}px`"></div>
                </div>
            </div>
        </div>

    </header>
</template>

<script>
export default {
    name: "HeaderTaps",
    components: {},
    props: {
        tabIndex: Number
    },
    data() {
        return {
            left: 0,
            top: 0,
            color: 255,
            show: true,
            isPhone: false
        }
    },
    methods: {

        clickTab(index) {
            const url = {
                0: '/',
                1: '/vaski-situation',
                2: '/business-service',
                3: '/news',
                4: '/contact-information'
            }
            this.$router.push(url[index])
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        getDome() {
            const doms = document.getElementsByClassName('tabs-name')[this.tabIndex]
            this.left = doms.offsetLeft
            this.show = false
        },
        handleScroll() {
            window.addEventListener('scroll', () => {
                const top = document.documentElement.scrollTop || document.body.scrollTop
                const index = top <= 100 ? top : 100
                this.top = index / 100
                this.color = this.top >= 1 ? 0 : 255
                console.log(this.color)
            });
            window.addEventListener('resize', () => {
                // location.reload();
            });
        },

    },
    mounted() {
        this.getDome()
        this.handleScroll()
        const top = document.documentElement.scrollTop || document.body.scrollTop
        const index = top <= 100 ? top : 100
        this.top = index / 100
        this.color = this.top >= 1 ? 0 : 255
    },

};
</script>

<style lang="scss" scoped>
.header-area {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 3px #d1d1d150;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 999;
    cursor: default;

    .logo {
        position: relative;

        img {
            width: 300px;
            margin-top: 40px;
        }
    }

    .tab-bottms {
        width: 100%;

    }

    .demo-tabs {
        height: 100%;
        display: flex;
        position: relative;
        color: #fff;

        .tabs-name {
            font-size: 20px;
            width: 130px;
            line-height: 100px;
            text-align: center;
            font-size: 16px;
            cursor: default;
        }

        .tab-bottm {
            width: 130px;
            height: 0%;
            border-bottom: solid 4px #296ea2;
            position: absolute;
            bottom: -3px;
            // transition: left 0.5s ease-in-out;
        }

    }

    .header-icon {
        display: none;
    }

    .tabs-drawer {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: #00000042;
        left: 0;
        top: 0;
        .drawer{
            width:50%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background-color: #fff;
            box-sizing: border-box;
            padding-top:50px
        }
        .drawer-logo {
            width: 60%;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: solid 1px #00000042;
            img {
                width: 100%
            }
        }
    }
}

.header-three-area {
    box-sizing: border-box;
    width: 1400px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: fixed;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}




@media (max-width: 767px) {
    .header-area {
        height: 50px;
        .logo {
            position: relative;

            img {
                width: 200px;
                margin-top: 15px;
            }
        }

        .tab-bottms {
            width: 100%;

        }

        .demo-tabs {
            display: none;
        }

        .tabs-drawer {
            display: block;

            .tabs-name {
                font-size: 20px;
                width: 100%;
                line-height: 50px;
                text-align: center;
                font-size: 15px;
                cursor: default;
            }
        }

        .header-icon {
            display: block;
            width: 40px;
            height: 40px;
            position: relative;
            top: 15px;
            transform: none;
       
        }
    }

    .header-three-area {
        box-sizing: border-box;
        padding: 0 20px;
    }
}
</style>
