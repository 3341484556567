<template>
  <HeaderTab :tabIndex="0"></HeaderTab>
  <div>
    <Swiper />
    <MerchantList />
    <NewsList />
    <IconList />
    <Footer />
  </div>
</template>

<script>
import HeaderTab from '../../components/Header.vue'
import Swiper from '../../components/Swiper.vue'
import MerchantList from '../../components/MerchantList.vue'
import NewsList from '../../components/NewsList.vue'
import IconList from '../../components/IconList.vue'
import Footer from '../../components/Footer.vue'
import axios from '../../http'
import CryptoJS from 'crypto-js'
import wx from "weixin-js-sdk"
export default {
  name: 'App',
  components: {
    Swiper,
    MerchantList,
    NewsList,
    IconList,
    Footer,
    HeaderTab
  },
  methods: {
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    getWXcode() {
      axios.get('/auth/weChatMPTicket', {}).then((res) => {
        const { noncestr, ticket, timestamp } = res.data.data || {}
        const data = {
          noncestr: noncestr,
          jsapi_ticket: ticket,
          timestamp: timestamp,
          url: window.location.href.split('#')[0],
        }
        const code = `jsapi_ticket=${data.jsapi_ticket}&noncestr=${data.noncestr}&timestamp=${data.timestamp}&url=${data.url}`
        const key = CryptoJS.SHA1(code).toString();
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx507a817966a379b5', // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.noncestr, // 必填，生成签名的随机串
          signature: key,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        // eslint-disable-next-line no-undef
        wx.ready(function () {
          wx.updateAppMessageShareData({
            title: '天空控股集团官方网站', // 分享标题
            desc: '城市未来产业链投资运营商\n城市服务·城市发展·市政工程\n文旅发展·传媒发展·信息技术', // 分享描述
            link: 'http://www.tikkon.cn/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/index_page.jpg', // 分享图标
            success: function () {
              console.log('设置成功')
              // 设置成功
            }
          })
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '天空控股集团官方网站', // 分享标题
            link: 'http://www.tikkon.cn/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/index_page.jpg', // 分享图标
            success: function () {
              console.log('设置成功')
              // 设置成功
            }
          })
        })
        // eslint-disable-next-line no-undef
        wx.error(function (res) {
          console.log(res, 'error')
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      })
    },

  },
  mounted() {
    if (this.is_weixn()) {
      this.getWXcode()
    }
  }
}
</script>

<style></style>