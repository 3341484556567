<template>
    <div id="icon-lit" class="list">
        <div class="list-in">
            <div class="list-item" v-for="item in iconList" :key="item">
                <img class="item-img" :src="item" alt="">
            </div>
        </div>
        <div class="list-in top">
            <div class="list-item" v-for="item in iconLists" :key="item">
                <img class="item-img" :src="item" alt="">
            </div>
        </div>

    </div>

</template>

<script>

export default {
    name: 'IcontList',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 880,
            startX: 0,
            endStartX: 0,
            left: -100,
            leftb: -100,
            isStatus: true,
            iconList: ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon1.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon2.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon3.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon4.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon5.png'],
            iconLists: ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon6.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon7.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon8.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon9.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/icon10.png'],
        }
    },
    methods: {
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            this.isStatus = flag;
        },
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiperlist = this.$refs.swiperlist
            if (this.endStartX !== 0) {
                if (this.endStartX - this.startX <= -50) {
                    swiperlist.next()
                }
                if (this.endStartX - this.startX >= 50) {
                    swiperlist.prev()
                }
            }

            this.endStartX = 0
            this.startX = 0
        },
    },
    mounted() {
        this.isPc()
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // 组件进入可视区域
                    console.log('组件进入可视区域');
                    this.left = 50
                    setTimeout(() => {
                        this.leftb = 50
                    }, 500)
                }
            });
        });
        // 获取需要监听的组件
        const target = document.getElementById('icon-lit');

        // 开始监听
        observer.observe(target);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.list {
    width: 1400px;
    height: 250px;
    box-sizing: border-box;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 100px 0;
    overflow: hidden;

    .list-in {
        position: absolute;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        animation: scroll-left 20s linear infinite;

        &:hover {
            animation-play-state: paused;
        }
    }

    .top {
        top: 130px;
    }


    .list-item {
        width: 264px;
        height: 108px;
        flex-shrink: 0;
        margin-right: 20px;
        overflow: hidden;

        .item-img {
            width: 100%;
        }


    }
}


@media (max-width: 767px) {
    .list {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 30px 0;
        overflow-x: auto;
        height: 100px;
        .top {
            top: 0;
        }

        .list-in {
            margin-bottom: 20px;
            position: relative;
            transform:none;
            transition: none;
            display: flex;
        }

        .list-item {
            width: 100px;
            height: 40px;
            flex-shrink: 0;
            margin-right: 20px;
            box-sizing: border-box;
            padding-top: 0;

            .item-img {
                width: 100%;
            }


        }
    }
}
</style>