<template>
    <div class="research">
        <div class="culture—value">
            <div class="culture—text">
                <div class="text">
                    <div>数据·智能·安全·集成</div>
                </div>
                <div class="message">
                    天空信息技术（北京）有限公司隶属于天空控股集团，拥有完善的管理系统和成熟的服务经验。公司始终专注培育企业的竞争机制，拥有完善的管理系统和成熟的服务经验。以打造一流水准的智慧物业、数字治理、信息安全为不懈目标，始终坚持“以人为本、品牌经营、服务至上”的企业发展战略理念。
                </div>
                <div class="message">
                    天空信息技术（北京）有限公司隶属于天空控股集团，拥有完善的管理系统和成熟的服务经验。公司始终专注培育企业的竞争机制，拥有完善的管理系统和成熟的服务经验。以打造一流水准的智慧物业、数字治理、信息安全为不懈目标，始终坚持“以人为本、品牌经营、服务至上”的企业发展战略理念。
                </div>
                <div class="message">
                    在竞争日趋激烈的市场里，我司秉承公司经营理念，不断拓展优化智慧系统，为客户提供优质高效的保障服务。
                </div>
                <div class="message">
                    在竞争日趋激烈的市场里，我司秉承公司经营理念，不断拓展优化智慧系统，为客户提供优质高效的保障服务。
                </div>
                <!-- <div class="btn">
                    信息技术官网
                </div> -->
            </div>
            <div class="right-page">
                <img class="right-img" src="@/assets/images/science-0.png" alt="">
                <img class="right-img" src="@/assets/images/science-1.png" alt="">
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ResearchBar',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {

        }
    },
    methods: {
    },
    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.research {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    .culture—value {
        width: 1450px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;
        margin-top: 60px;
        margin-bottom: 60px;
        padding: 60px 0;
        padding-right: 60px ;
        background: url('../../assets/images/service-bg-icon.png') no-repeat;
        background-position: 0px 600px;
        background-size: 600px;
        background-color: #f3f3f3;

        .culture—text {
            flex: 1;
            box-sizing: border-box;
            padding: 70px;
            padding-top: 0;

            .btn {
                width: 120px;
                height: 40px;
                background-color: #296ea2;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
                margin-top: 80px;
            }

            .text {
                font-size: 33px;
                line-height: 50px;
                margin-bottom: 50px;
                color: #2a6e9f;
                position: relative;
                
                &::after {
                    content: '';
                    width: 30px;
                    border-bottom: solid 2px #2a6e9f;
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                }
            }

            .message {
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                color: #7c7c7c;
                margin-bottom: 20px;
            }


        }

        .right-page {
            flex:1;
            z-index: 99;
            box-sizing: border-box;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .right-img {
                width: 681px;
                margin-bottom: 30px;
            }

        }

    }



}


@media (max-width: 767px) {
    .research {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        box-sizing: border-box;
        padding-bottom: 10px;
        padding-bottom: 10px;


        .culture—value {
            width: 100%;
            padding: 0px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            display: block;

            .culture—text {
                width: 100%;
                text-align: center;
                padding: 20px;
                .btn {
                 margin: 0 auto;
                }
                .text {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 20px;
                    color: #2a6e9f;
                    &::after {
                        border-bottom: none;
                    }
                }

                .message {
                    font-size: 12px;
                    font-weight: 500;
                    color: #2a6e9f;
                    text-indent: 2em;
                    text-align: left;
                }


            }

            .right-page {
                width: 100%;
                z-index: 99;
                box-sizing: border-box;
                color: #fff;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                display: flex;
                padding: 0;

                .right-img {
                    width: 100%;
                    height: auto;
                }



            }

        }


    }

}
</style>