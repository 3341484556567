<template>
  <HeaderTab :tabIndex="1"></HeaderTab>
  <div class="vaski">
    <TabInfo :tabIndex="3" />
    <Member  />
    <Footer />
  </div>
</template>

<script>
import HeaderTab from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import Member from '../../components/vaski/Member.vue'
// import NullError from '../../components/NullError.vue'
import TabInfo from '../../components/vaski/TabInfo.vue'
export default {
  name: 'VaskiDom',
  components: {
    Footer,
    Member,
    HeaderTab,
    TabInfo
  },
  data() {
    return {
    
    }
  },
  methods: {

  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.vaski {
  width: 100%;
  overflow: hidden;
  cursor: default;

}
</style>