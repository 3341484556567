<template>
    <div>
        <div :class="['member-bdy', index % 2 !== 0 ? 'member-bdy-none' : '', index == 0 ? 'member-bdy-img' : '']"
            v-for="(item, index) in data_list" :key="index">
            <div class="new-member">
                <div class="situation—value">
                    <div class="situation—text">
                        <div class="text">
                            <div>{{ item.name }}</div>
                        </div>
                        <div class="message">{{ item.message }}</div>
                    </div>
                </div>
                <div id="member-lit" class="member-lit">
                    <div class="flex_page" v-for="e in item.img" :key="e">
                        <div class="user" v-for="i in e" :key="i">
                            <img class="img" :src="i" alt="">
                            <!-- <div class="value">
                            <div class="name">姓名姓名</div>
                            <div class="text">职位职位职位职位职位</div>
                        </div> -->
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>


</template>

<script>
export default {
    name: 'MemBer',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            data_list: [
                {
                    name: '集团管理团队',
                    message: 'Group Management Team',
                    img: [
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_0.png'],
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_1.png', 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_2.png'],
                        [
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_3.png',
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_4.png',
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_5.png',
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_6.png',
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_7.png'
                        ],

                    ]
                },
                {
                    name: '城市服务板块管理团队',
                    message: 'Service Management team',
                    img: [
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_8.png'],
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_9.png','https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_20.png'],

                    ]
                },
                {
                    name: '城市发展板块管理团队',
                    message: 'Share Management team',
                    img: [
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_10.png'],
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_11.png'],

                    ]
                },
                {
                    name: '市政工程板块管理团队',
                    message: 'Construction Management team',
                    img: [
                        [
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_12.png',
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_13.png'
                        ],
                        [

                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_14.png',
                            'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_15.png'
                        ],

                    ]
                },
                {
                    name: '文旅发展板块管理团队',
                    message: 'Joy Management team',
                    img: [
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_16.png'],

                    ]
                },
                {
                    name: '传媒发展板块管理团队',
                    message: 'Media Management team',
                    img: [
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_18.png'],

                    ]
                },
                {
                    name: '信息技术板块管理团队',
                    message: 'Smart Management team',
                    img: [
                        ['https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/people_19.png'],

                    ]
                },
            ]
        }
    },
    methods: {

    },
    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/*引用字库*/
@font-face {
    font-family: "MontserratMedium";
    src: url("../../assets/font/Montserrat-Medium.otf");
}

@keyframes example1 {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

.member-bdy-none {
    background: none !important;
    background-color: #fff !important;
}

.member-bdy-img {
    background: url('../../assets/images/member-bg.png') no-repeat;
    background-position: 0px 200px;
}

.member-bdy {

    background-color: #f3f3f3;
    margin-bottom: 20px;

    .new-member {
        display: flex;
        width: 1400px;
        margin: 0 auto;
        position: relative;
        animation: example1 1s ease-out 0s backwards;
        box-sizing: border-box;
        padding: 50px 0;

        .situation—value {
            flex: 3;
            color: #276999;

            .situation—text {

                .text {
                    font-size: 24px;
                    line-height: 40px;
                    font-family: MontserratMedium;
                }

                .message {
                    font-size: 17px;
                    line-height: 29px;
                }

                .span {
                    font-size: 15px;
                    display: inline-block;
                    margin-bottom: 28px;
                }

                position: relative;
            }

        }

        .member-lit {
            flex: 10;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            position: relative;
            flex-direction: column;

            .flex_page {
                display: flex;

                .user {
                    flex-shrink: 0;
                    width: 160px;
                    height: 216px;
                    margin-bottom: 40px;
                    position: relative;
                    overflow: hidden;
                    margin-right: 40px;

                    .img {
                        width: 100%;

                    }

                    &:hover {
                        .value {
                            bottom: 0;

                        }
                    }

                    .value {
                        width: 100%;
                        height: 80px;
                        background: #286da2cc;
                        position: absolute;
                        bottom: -100%;
                        left: 0;
                        color: #fff;
                        box-sizing: border-box;
                        padding: 20px 40px;
                        transition: bottom 0.5s ease-in-out;

                        .name {
                            font-size: 20px;
                        }

                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }

        }
    }
}



@media (max-width: 767px) {
    .member-bdy {
        background: url('../../assets/images/member-bg.png') no-repeat;
        background-position: 0px 200px;
        background-color: #f3f3f3;

        .new-member {
            display: block;
            width: 100%;
            margin: 0 auto;
            position: relative;
            animation: example1 1s ease-out 0s backwards;
            box-sizing: border-box;
            padding: 21px 0;

            .situation—value {
                flex: 4;
                color: #276999;

                .situation—text {
                    height: 60px;
                    text-align: center;

                    .text {
                        font-size: 20px;
                        line-height: 30px;
                        margin-bottom: 0;
                        font-family: MontserratMedium;
                    }

                    .message {
                        font-size: 12px;
                        line-height: 20px;
                    }

                    .span {
                        font-size: 15px;
                        display: inline-block;
                        margin-bottom: 28px;
                    }

                    position: relative;
                }

            }

            .member-lit {
                flex: 8;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                position: relative;
                justify-content: center;

                .flex_page {
                    flex: 1;
                    justify-content: center;
                    flex-wrap: wrap;

                    .user {
                        flex-shrink: 0;
                        width: 45%;
                        height: auto;
                        margin-bottom: 20px;
                        position: relative;
                        overflow: hidden;
                        margin:0 2%;

                        .img {
                            width: 100%;

                        }

                        .value {
                            width: 100%;
                            height: 80px;
                            background: #286da2cc;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            color: #fff;
                            box-sizing: border-box;
                            padding: 20px 40px;
                            transition: bottom 0.5s ease-in-out;

                            .name {
                                font-size: 20px;
                            }

                            .text {
                                font-size: 14px;
                            }
                        }
                    }
                }

            }
        }
    }


}
</style>