<template>
    <div class="research">
        <div class="culture—value">
            <div class="culture—text">
                <div class="text">
                    <div>能源·资产·股权·合作</div>
                </div>
                <div class="message">
                    江苏天空城市发展有限公司成立于2014年，公司专注于全国范围内不动产项目合作的物业服务公司。我们以股权合作的方式，携手志同道合的合伙人，共同开发、运营和管理各类不动产项目。我们深知不动产项目的复杂性和挑战性，因此我们的团队具备丰富的行业经验和专业知识，能够为客户提供全方位的物业服务，确保项目的成功运营和长期价值。 
                </div>
                <div class="message">
                    我们坚信，合作伙伴是我们成功的关键。因此我们始终秉持着诚信、专业、创新和共赢的经营理念，与合伙人紧密合作，共同制定项目规划、投资策略和运营方案，确保项目的顺利进行以及双方利益的最大化。
                </div>
                <div class="message">
                     展望未来，我们将继续深耕不动产领域，不断创新服务模式，拓展业务领域，与更多优秀的合伙人携手共进，共创不动产行业的新篇章。
                </div>
                <!-- <div class="btn">城市发展官网</div> -->
            </div>
            <div class="right-page">
                <el-carousel ref="swiper" :height="`${height}px`" :autoplay="false" :interval="6000" @touchstart="startSwipe"
                    @touchmove="swipe" @touchend="endSwipe">
                    <el-carousel-item v-for="item in imgList" :key="item">
                        <img class="right-img" :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>

            </div>
        </div>

    </div>
</template>

<script>
import img1 from '../../assets/images/city-0.png'
export default {
    name: 'ResearchBar',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 580,
            startX: 0,
            endStartX: 0,
            imgList: [
            img1
            ]
        }
    },
    methods: {
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiper = this.$refs.swiper
            if (this.endStartX - this.startX <= -50) {
                swiper.next()
            }
            if (this.endStartX - this.startX >= 50) {
                swiper.prev()
            }
        },
        getHeight() {
            if (this.isPc()) {
                this.height = 580
                // PC端逻辑
            } else {
                // 手机端逻辑
                this.height = 220
            }
        },
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        }
    },
    mounted() {
        this.getHeight()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.research {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    :deep(.el-carousel .el-carousel__indicators) {
        left: 100%;
        transform: translateX(-110%);
        display: flex;

        .el-carousel__button {
            background-color: #fff;
            opacity: 1;
            border-radius: 0;
            height: 4px;
            width: 60px;
        }

        .is-active .el-carousel__button {
            background-color: #409eff;
        }
    }
    .culture—value {
        width: 1450px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;
        margin-top: 60px;
        margin-bottom: 60px;
        padding-left: 60px;
        background: url('../../assets/images/service-bg-icon.png') no-repeat;
        background-position: 0px 450px;
        background-size: 600px;
        background-color: #f3f3f3;

        .culture—text {
            flex: 1;
            box-sizing: border-box;
            padding-right: 60px;
            padding-top: 60px;


            .btn {
                width: 120px;
                height: 40px;
                background-color: #296ea2;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
                margin-top: 80px;
            }

            .text {
                font-size: 33px;
                line-height: 50px;
                margin-bottom: 50px;
                color: #2a6e9f;
                position: relative;

                &::after {
                    content: '';
                    width: 30px;
                    border-bottom: solid 2px #2a6e9f;
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                }
            }

            .message {
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                color: #7c7c7c;
                margin-bottom: 20px;
            }


        }

        .right-page {
            z-index: 99;
            box-sizing: border-box;
            width: 850px;
            position: relative;
            .right-img {
                width: 850px;
                height: 580px;
            }

        }

    }



}


@media (max-width: 767px) {
    .research {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        box-sizing: border-box;
        padding-bottom: 10px;
        padding-bottom: 10px;


        .culture—value {
            width: 100%;
            padding: 0px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            display: block;
            background-image: none;
            .culture—text {
                width: 100%;
                text-align: left;
                padding: 20px;
                .btn {
                 margin: 0 auto;
                }
                .text {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 2%;
                    color: #2a6e9f;
                    text-align: center;
                    &::after {
                        border-bottom: none;
                    }
                }

                .message {
                    font-size: 12px;
                    font-weight: 500;
                    text-indent: 2em;
                    line-height: 30px;
                    color: #2a6e9f;
                }


            }

            .right-page {
                z-index: 99;
                box-sizing: border-box;
                color: #fff;
                padding: 0;
                width: 100%;
                height: auto;
                top: 0px;
                .right-img {
                    width: 100%;
                    height: auto;
                }



            }

        }


    }

}
</style>