<template>
    <div class="block">
        <el-carousel ref="swiper" :height="`${height}px`" :autoplay="false" :interval="6000" @touchstart="startSwipe"
            @touchmove="swipe" @touchend="endSwipe">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
                <div class="drawer-logo">
                    <div class="drawer-text">
                        <span class="text-name">未来<span>城市 天空</span>同行</span>
                        <span class="text">FUTURE <span class="wo">CITY</span> </span>
                        <span class="text"> <span class="wo">TIKKON</span> MOVE FORWARD</span>
                        <span class="text"><span class="wo">TOGETHER</span></span>
                    </div>
                    <img :src="item.url" />
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    name: 'SwiperInfo',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 880,
            startX: 0,
            endStartX: 0,
            imgList: [
                {
                    url: 'https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/page.png',
                    name: '未来城市 天空同行',
                    text1: 'FUTURE CITY',
                    text2: 'TIKKON MOVE FORWARD',
                    text3: 'TOGETHER',
                }
            ]
        }
    },
    methods: {
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiper = this.$refs.swiper
            if (this.endStartX - this.startX <= -50) {
                swiper.next()
            }
            if (this.endStartX - this.startX >= 50) {
                swiper.prev()
            }
        },
        getHeight() {
            if (this.isPc()) {
                this.height = 880
                // PC端逻辑
            } else {
                // 手机端逻辑
                this.height = 450
            }
        },
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        }
    },
    mounted() {
        this.getHeight()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.block {
    width: 100%;
    height: auto;
    position: relative;

    .drawer-logo {
        width: 100%;
        height: auto;
        overflow: hidden;

        .drawer-text {
            position: absolute;
            z-index: 10;
            display: flex;
            flex-direction: column;
            left: 20%;
            top: 30%;
            color: #005284;

            .text-name {
                font-size: 63px;
                line-height: 140px;

                span {
                    font-weight: 700;
                }
            }

            .text {
                font-size: 25px;
                line-height: 40px;
                .wo{
                    font-weight: 600;
                }
            }
        }

        img {
            z-index: 0;
            width: 1920px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .block_h5 {
        display: none;
    }
}

@media (max-width: 767px) {
    .block {

        .drawer-logo {

            .drawer-text {
                left: 10%;
                top: 30%;
                width: 80%;

                .text-name {
                    font-size: 28px;
                    line-height: 50px;
                    margin-bottom: 10px;
                    text-shadow: 2px 2px 5px #fdfdfd;
                }

                .text {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            img {
                z-index: 0;
                width: 1000px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .block_h5 {
            display: none;
        }
    }

}
</style>