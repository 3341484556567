<template>
    <div class="culture">
        <div class="culture—value">
            <div class="culture—text">
                <div class="text">
                    <div>未来<div class="jia">城市 天空</div>同行</div>
                </div>
                <div class="message">FUTURE <div class="jia">CITY</div></div>
                <div class="message"><div class="jia">TIKKON</div> MOVE FORWARD</div>
                <div class="message"><div class="jia">TOCETHER</div></div>
            </div>
            <div class="right-page">
                <div class="name">团队精神</div>
                <div class="span">
                    专业 敬业 乐业
                </div>
                <div class="name">核心价值</div>
                <div class="span">
                    创造社会价值 共享发展权益
                </div>
                <div class="name">企业德训</div>
                <div class="span">
                    正善厚谦 礼德慧信
                </div>
                <div class="name">企业精神</div>
                <div class="span">
                    追求卓越 崇尚务实<br />
                    放眼天空 脚踏实地 <br />
                </div>
                <div class="name">企业愿景</div>
                <div class="span">
                    成为国内知名、行业先进的
                    <br/>
                    城市未来产业发展领导品牌
                </div>
                <!-- <div class="name">团队管理</div>
                <div class="span">
                    爱国 爱岗 <br />
                    团结 协作 <br />
                    敬业 乐业
                </div> -->
            </div>
        </div>
        <div class="img-page">
            <img class="img" src="@/assets/images/nnwmbtfn.png" alt="">
        </div>
    </div>

</template>

<script>
export default {
    name: 'CulTure',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 880,
            startX: 0,
            endStartX: 0,
            left: -100,
            leftb: -100,
            isStatus: true
        }
    },
    methods: {
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            this.isStatus = flag;
        },
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiperlist = this.$refs.swiperlist
            if (this.endStartX !== 0) {
                if (this.endStartX - this.startX <= -50) {
                    swiperlist.next()
                }
                if (this.endStartX - this.startX >= 50) {
                    swiperlist.prev()
                }
            }

            this.endStartX = 0
            this.startX = 0
        },
    },
    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.culture {
    width: 100%;
    margin: 0 auto;

    .culture—value {
        width: 1400px;
        padding-top: 90px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        padding-bottom: 150px;

        .culture—text {
            height: 200px;

            .text {
                font-size:39px;
                line-height: 58px;
                margin-bottom: 30px;
                color: #2a6e9f;
                display: flex;
                align-items: flex-end;
              
            }
            .jia{
                    font-weight: 700;
                    display: inline;

                }
            .message {
                font-size: 21px;
                line-height: 34px;
                font-weight: 300;
                color: #2a6e9f;
            }
            position: relative;
            animation: example2 1s ease-out 0s backwards;


        }
        @keyframes example2 {
            0% {
                left: -100%;
                opacity: 0;
            }

            100% {
                left: 0;
                opacity: 1;
            }
        }
        @keyframes example1 {
            0% {
                left: -100%;
                opacity: 0;
            }

            100% {
                left: 60%;
                opacity: 1;
            }
        }
        .right-page {
            position: absolute;
            z-index: 99;
            width: 458px;
            height: 680px;
            background:url(https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/bgc.png);
            background-size: 100% ;
                left: 60%;
            top: 90px;
            box-sizing: border-box;
            padding:50px 60px;
            color: #fff;
            animation: example1 1s ease-out 0s backwards;

            .name {
                font-size: 17px;
                margin-bottom: 10px;
                position: relative;
                text-indent: 20px;
                line-height: 25px;
                font-weight: 600;
                &::after {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 2px;
                    background: #fff;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .span {
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 20px;
                line-height: 22px;
            }
        }

    }

    .img-page {
        width: 1920px;

        .img {
            width: 100%;
        }
    }
}


@media (max-width: 767px) {
    .culture {
    width: 100%;
    margin: 0 auto;

    .culture—value {
        width: 100%;
        padding-top: 20px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        padding-bottom:50px;
        .culture—text {
            height: auto;
            box-sizing: border-box;
            padding-left: 5%;
            .text {
                font-size: 30px;
                line-height: 30px;
                margin-bottom: 10px;
                color: #2a6e9f;
                text-align: left;
            }

            .message {
                font-size: 16px;
                font-weight: 300;
                color: #2a6e9f;
                text-align: left;
            }


        }

        .right-page {
            position: relative;
            z-index: 99;
            width: 90%;
            height: 550px;
            background-color: #286ea2;
            left: 50%;
            transform: translateX(-50%);
            top: 30px;
            box-sizing: border-box;
            padding:20px 30px;
            color: #fff;

            .name {
                font-size: 16px;
                margin-bottom: 5px;
                position: relative;
                text-indent: 20px;

                &::after {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 2px;
                    background: #fff;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .span {
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 15px;
            }
        }

    }

    .img-page {
        width: 100%;
        overflow: hidden;
        .img {
            width: 500%;
            margin-bottom: 20px;
             display: none;
        }
    }
}

}
</style>