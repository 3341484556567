<template>
  <div v-loading="loading">
    <div class="liaison-map">
      <div class="left">
        <div class="name">关注我们</div>
        <div class="left-icon">
          <div v-for="item in 1" :key="item" class="img hovericon">
            <img src="@/assets/images/iconwxp.png" alt="">
            <div class="ma">
              <img src="@/assets/images/wx.jpg" alt="">
            </div>

          </div>
        </div>
        <div class="message">第一时间了解天空咨询</div>
        <div class="name">
          提交需求
          <el-radio-group v-model="name_value">
            <el-radio :value="'意见建议'">意见建议</el-radio>
            <el-radio :value="'商务合作'">商务合作</el-radio>
          </el-radio-group>
        </div>

        <div class="input-name">姓名</div>
        <div class="input">
          <el-input class="value" v-model="value1" />
        </div>
        <div class="input-name">联系电话</div>
        <div class="input">
          <el-input class="value" v-model="value2" />
        </div>
        <div class="input-name">您的需求</div>
        <div class="input">
          <el-input class="value textarea" v-model="value3" rows="4" type="textarea" resize="none" />
        </div>
        <div class="btn" @click="toValue">
          提交需求
        </div>
        <div class="map-text-ccc">
          <div class="text"><img class="img" src="@/assets/images/icons.png" alt="">江苏省常州市武进区常武路天空中心</div>
          <div class="text img-info"><img class="img " src="@/assets/images/iconss.png" alt="">400-060-9966</div>
          <div class="text img-info"><img class="img " src="@/assets/images/icons_email.png" alt="">group@tikkon.com
          </div>
          <!-- <div class="text">
            <div class="img"></div> 0519-86669988
          </div> -->
          <!-- <div class="text"><img class="img" src="@/assets/images/iconsss.png" alt="">0519-88876667</div> -->
        </div>
      </div>

      <div class="right">
        <div class="name">联系方式</div>
        <div id="container" class="container">
        </div>

      </div>

    </div>
    <div class="bottom-img">
      <div class="bottom-right">
        <div class="name">加入我们</div>
        <div class="text">
          在这里，汇聚人才，引领变革
        </div>
        <div class="text">请将您的简历与作品发送到career@tikkon.cn，我们会尽快与您联系</div>
        <el-upload :before-upload="handleBeforeUpload" class="upload-demo" multiple>
          <el-button class="btn" type="primary">上传简历</el-button>
        </el-upload>
      </div>
      <img class="img" src="@/assets/images/1712808650751.png" alt="">
    </div>
  </div>
</template>

<script>
import axios from '../../http'
import { ElMessage } from 'element-plus'
export default {
  name: "MapInfo",
  components: {},
  props: {},
  data() {
    return {
      input: '',
      map: null,
      state: {
        markerLayer: '',
        marker: null,
        infoWindow: null,
        value: 1,

      },
      fileList: [],
      file: null,
      value3: null,
      value1: null,
      value2: null,
      loading: false,
      name_value: '商务合作'
    };
  },
  methods: {
    noneHref() {
      window.history.back();
    },
    init() {
      const TMap = window.TMap
      const center = new TMap.LatLng(31.717067200291, 119.95802737866)
      const map = new TMap.Map(document.getElementById('container'), {
        center: center, // 设置地图中心点坐标
        zoom: 19, // 设置地图缩放级别
        viewMode: '2D',
      })
      new TMap.MultiMarker({
        map: map,
        geometries: [{ position: new TMap.LatLng(center.lat, center.lng) }],
      })
      var a = document.querySelector('canvas+div:last-child')
      a.style.display = 'none'
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      console.log(file)
      this.file = file
      this.poste()
      return false;
    },
    poste() {

      this.loading = true
      let formData = new FormData()
      formData.append('to', 'career@tikkon.cn')
      formData.append('subject', '简历')
      formData.append('text', '简历')
      formData.append('file', this.file)
      axios.post('/resource/email/send', formData)
        .then(response => {
          console.log(response)
          ElMessage.success('简历提交成功！')
          this.loading = false
        })
        .catch(error => {
          console.error(error); // 处理错误情况
          this.loading = false
        });
    },
    toValue() {

      if (!this.value1 || !this.value2 || !this.value3) {
        ElMessage.error('联系方式及需求不能为空！')
        return
      }
      this.loading = true
      let formData = new FormData()
      formData.append('to', 'service@vaski.cn')
      formData.append('subject', '需求')
      formData.append('text', `姓名:${this.value1}\n联系电话:${this.value2}\n需求:${this.value3}\n`)
      axios.post('/resource/email/send', formData)
        .then(response => {
          ElMessage.success('联系方式提交成功！')
          console.log(response)
          this.loading = false
        })
        .catch(error => {
          console.error(error); // 处理错误情况
          this.loading = false
        });
    }


  },
  mounted() {
    this.init()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.bottom-img {
  width: 1400px;
  margin: 0 auto;

  img {
    width: 100%;
    margin: 50px 0;
  }

  position: relative;

  .bottom-right {
    position: absolute;
    width: 55%;
    height: 300px;
    right: 0;
    top: 70px;

    .name {
      font-size: 24px;
      color: #fff;
      border-bottom: solid 2px #fff;
      line-height: 80px;
      margin-bottom: 40px;

    }

    .text {
      font-size: 16px;
      color: #fff;
      line-height: 30px;
    }

    .btn {
      margin-top: 50px;
      width: 150px;
      height: 40px;
      font-size: 16px;
      background: #fff;
      color: #286ea2;
    }
  }
}

.liaison-map {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  position: relative;
  margin-top: 50px;

  .name {
    font-size: 25px;
    line-height: 50px;
    width: 100%;
    text-align: left;
    border-bottom: solid 1px #76767696;
    color: #181818;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    width: 580px;
    box-sizing: border-box;
    padding-right: 60px;
    position: relative;

    .img-info {
      letter-spacing: 1px;
    }

    .left-icon {
      display: flex;
      width: 100%;
      margin: 30px 0;

      .hovericon {
        position: relative;

        .ma {
          position: absolute;
          width: 200px;
          height: 200px;
          background-color: #fff;
          left: -200px;
          top: -200px;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 5px;
          transition: opacity 0.5s ease-in-out;
          display: none;
        }

        &:hover {
          .ma {
            display: block;
          }
        }
      }

      .img {
        width: 60px;
        margin-right: 20px;

        img {
          width: 100%;
        }
      }

    }

    .message {
      font-size: 16px;
      color: #000;
      margin-bottom: 50px;
    }

    .input-name {
      font-size: 16px;
      color: #7c7c7c;
      line-height: 40px;
      margin-top: 20px;
    }

    .input {
      width: calc(100% - 50px);
      position: relative;

      .value {
        height: 40px;
      }

      .textarea {
        height: 100px;
      }

      &::after {
        content: '*';
        position: absolute;
        font-size: 20px;
        color: #f00;
        top: 50%;
        transform: translateY(-40%);
        right: -30px;
      }
    }

    .btn {
      width: 200px;
      height: 50px;
      font-size: 20px;
      background: #7c7c7c;
      color: #fff;
      line-height: 50px;
      text-align: center;
    }
  }

  .right {
    flex: 2;
    position: relative;

    .container {
      position: relative;
      width: 850px;
      height: 620px;
      margin-top: 30px;
    }


  }

  .map-text-ccc {
    width: 350px;
    height: 120px;
    background: #ffffffc4;
    position: absolute;
    top: 120px;
    right: -400px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    z-index: 99;

    .text {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 10px
    }

    .img {
      width: 20px;
      height: 20px;
      margin-right: 10px
    }
  }
}

@media (max-width: 767px) {
  .bottom-img {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    margin-top: 20px;

    img {
      width: 300%;
      margin: 0px 0;
      margin-bottom: 180px;
    }


    .bottom-right {
      position: absolute;
      width: 100%;
      height: 200px;
      right: 0;
      top: 280px;
      background-color: #286ea2;
      box-sizing: border-box;
      padding: 20px;

      .name {
        font-size: 20px;
        color: #fff;
        border-bottom: solid 1px #fff;
        line-height: 30px;
        margin-bottom: 10px;
      }

      .text {
        font-size: 12px;
        color: #fff;
        line-height: 20px;
      }

      .btn {
        margin-top: 20px;
        width: 100px;
        height: 30px;
        font-size: 14px;
        background: #fff;
        color: #286ea2;
      }
    }
  }

  .liaison-map {
    width: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
    box-sizing: border-box;
    padding: 0 20px;

    .name {
      font-size: 20px;
      line-height: 50px;
      width: 100%;
      text-align: center;
      border-bottom: solid 1px #76767696;
      color: #181818;
      display: none;
    }

    .left {
      width: 100%;
      box-sizing: border-box;
      padding-right: 0;

      .left-icon {
        display: flex;
        width: 100%;
        margin: 30px 0;
        justify-content: space-around;

        .img {
          width: 50px;
          margin-right: 20px;

          img {
            width: 100%;
          }
        }
      }

      .message {
        font-size: 20px;
        color: #7c7c7c;
        margin-bottom: 20px;
        text-align: center;
      }

      .input-name {
        font-size: 12px;
        color: #7c7c7c;
        line-height: 40px;
        margin-top: 20px;
      }

      .input {
        width: calc(100%);
        position: relative;

        .value {
          height: 30px;
        }

        .textarea {
          height: 100px;
        }

        &::after {
          content: '';
          display: none
        }
      }

      .btn {
        width: 100%;
        height: 50px;
        font-size: 20px;
        background: #7c7c7c;
        color: #fff;
        line-height: 50px;
        margin-top: 30px;
        text-align: center;
      }
    }

    .right {
      flex: 2;
      position: relative;

      .container {
        position: relative;
        width: 100%;
        height: 200px;
        margin-top: 30px;
      }


    }

    .map-text-ccc {
      width: 100%;
      height: 80px;
      background: #ffffffc4;
      position: relative;
      top: 0;
      right: 0;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 0;
      z-index: 99;
      margin-top: 30px;

      .text {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 10px
      }

      .img {
        width: 20px;
        height: 20px;
        margin-right: 10px
      }
    }
  }
}
</style>
