<template>
  <div>
    <div>
      <div class="situation">
        <div class="situation—value">
          <div class="situation—text">
            <div class="text">
              <div>天空控股集团</div>
              <div>有限公司</div>
            </div>
            <div class="message">TIKKON HOLDINGS</div>
            <div class="message">GROUP CO, LTD.</div>
            <div class="texts">
              <div>天空控股集团有限公司</div>
            </div>
            <div class="messages">TIKKON HOLDINGS GROUP CO, LTD.</div>
          </div>
        </div>
        <div class="situation—value">
          <div class="situation—text">
            <span class="span">
              天空控股集团有限公司，2010年成立于常州，产业分为“城市服务、城市发展、市政工程、文旅发展、传媒发展、信息技术”六大事业板块，下辖不同领域专业主体，致力于建设：专业敬业的人才团队、辐射全国的业务渠道、行业领先的优势产品。
            </span>
            <span class="span">
              集团管理团队规模逾百人，用工规模逾千人，业务范围涵盖：城市物业、城市环卫、安保安防、电梯工程；能源投资、资产管理、股权并购、大宗物资；市政工程、装饰工程、园林绿化、安装工程；乐园投资、文旅运营、潮流娱乐、酒店管理；品牌管理、直播电商、视频短剧、手机游戏；数据管理、人工智能、信息安全、系统集成。
            </span>
            <span class="span">
              在落实高质量发展的过程中，“天空”不断探索市场需求，提供降本增效的新类型产品；在实现伟大复兴的进程中，“天空”积极弘扬民族自信，抵御外来文化及意识形态渗透；在全面建成社会主义现代化强国的道路上，“天空”始终坚持党建引领，履行企业的社会责任与时代使命。
            </span>
            <span class="span">
              集团立身之本：以高水平的科技类、服务类、消费类产品，切实提升客户体验，有益行业、有益社会、有益人民、有益国家。
            </span>
          </div>
        </div>
      </div>
      <div class="img-page">
        <img class="img imgMax" src="https://tikkon.oss-cn-hangzhou.aliyuncs.com/web/images/company_img.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SituaTion",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      height: 880,
      startX: 0,
      endStartX: 0,
      left: -100,
      leftb: -100,
      isStatus: true,
    };
  },
  methods: {
    isPc() {
      var userAgentInfo = navigator.userAgent;
      var agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var i = 0; i < agents.length; i++) {
        if (userAgentInfo.indexOf(agents[i]) > 0) {
          flag = false;
          break;
        }
      }
      this.isStatus = flag;
    },
    startSwipe(e) {
      this.startX = e.touches[0].clientX;
    },
    swipe(e) {
      this.endStartX = e.touches[0].clientX;
    },
    endSwipe() {
      const swiperlist = this.$refs.swiperlist;
      if (this.endStartX !== 0) {
        if (this.endStartX - this.startX <= -50) {
          swiperlist.next();
        }
        if (this.endStartX - this.startX >= 50) {
          swiperlist.prev();
        }
      }

      this.endStartX = 0;
      this.startX = 0;
    },
  },
  mounted() { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.situation {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  padding: 90px 0;
  padding-bottom: 0;

  .situation—value {
    flex: 6;

    .situation—text {
      height: 400px;

      .text {
        font-size: 39px;
        line-height: 55px;
        margin-bottom: 30px;
      }
      .texts{
        display: none;
      }
      .message {
        font-size: 22px;
        line-height: 29px;
      }
      .messages {
        display: none;
      }
      .span {
        font-size: 15px;
        display: inline-block;
        margin-bottom: 28px;
      }

      position: relative;
      animation: example1 1s ease-out 0s backwards;
    }

    @keyframes example1 {
      0% {
        left: -100%;
        opacity: 0;
      }

      100% {
        left: 0;
        opacity: 1;
      }
    }
  }

  .situation—value:first-child {
    flex: 4;
    margin-right: 20px;
  }
}

.img-page {
  width: 1400px;
  margin: 0 auto;
  margin-bottom: 146px;

  .img {
    width: 100%;
    height: 250px;
  }

  .imgMax {
    height: 525px;
  }

  position: relative;
  animation: example1 1s ease-out 0s backwards;
}

@media (max-width: 767px) {
  .situation {
    width: 100%;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
    padding-top: 30px;

    .situation—value {
      width: 100%;

      .situation—text {
        height: auto;
        margin-bottom: 20px;

        .text {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 10px;
          text-align: center;
          display: none;
        }

        .message {
          font-size: 12px;
          text-align: center;
          display: none;

        }
        .texts {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 10px;
          text-align: center;
          display: block;
        }

        .messages {
          font-size: 12px;
          text-align: center;
          display: block;

        }
        .span {
          font-size: 14px;
          display: inline-block;
          margin-bottom: 30px;
          box-sizing: border-box;
          padding: 0 20px;
        }
      }
    }

    .situation—value:first-child {
      width: 100%;
      margin-right: 0;
    }
  }

  .img-page {
    width: 100%;
    margin-bottom: 0;

    .img {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  }
}
</style>
