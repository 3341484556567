<template>
    <div class="research">
        <div class="culture—value">
            <div class="culture—text">
                <div class="text">
                    <div>物业·环卫·安保·电梯</div>
                </div>
                <div class="message">
                    在长期的城市服务领域的深耕细作中，天空集团不断总结经验，开创了城市服务一体化的先进理念，科学而有效的整合了城市服务的不同细分领域，如：空气环境维护、水环境维护、城乡一体化环卫服务、综合物业服务、市政管护服务等。
                </div>
                <div class="message">
                    经过系统性的整合管理后，政府由繁重的城市公共服务提供者转变为监管者，城市服务作业情况由小、散、乱的局面转变为品牌、系统、专业的理想管理状态，从而整体提升城市的自然环境、人居环境、投资环境。</div>
                <!-- <div class="btn">
                    城市服务官网
                </div> -->
            </div>
            <div class="right-page">
                <img class="right-img" src="@/assets/images/service-bg.png" alt="">

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ResearchBar',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {

        }
    },
    methods: {
    },
    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.research {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    .culture—value {
        width: 1450px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;
        margin-top: 60px;
        margin-bottom: 60px;

        .culture—text {
            flex: 2;
            box-sizing: border-box;
            padding: 70px;
            background: url('../../assets/images/service-bg-icon.png') no-repeat;
            background-position: 0px 450px;
            background-size: 120%;
            background-color: #f3f3f3;
            height: 580px;

            .btn {
                width: 120px;
                height: 40px;
                background-color: #296ea2;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
                margin-top: 80px;
            }

            .text {
                font-size: 33px;
                line-height: 50px;
                margin-bottom: 50px;
                color: #2a6e9f;
                position: relative;

                &::after {
                    content: '';
                    width: 30px;
                    border-bottom: solid 2px #2a6e9f;
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                }
            }

            .message {
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                color: #7c7c7c;
                margin-bottom: 20px;
            }


        }

        .right-page {
            flex: 3;
            z-index: 99;
            box-sizing: border-box;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .right-img {
                width: 850px;
                height: 580px;
            }

        }

    }



}


@media (max-width: 767px) {
    .research {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        box-sizing: border-box;
        padding-bottom:10px;
        padding-top:0;


        .culture—value {
            width: 100%;
            padding-top: 0px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            display: block;

            .culture—text {
                width: 100%;
                text-align: left;
                padding: 20px;
                height: 300px;
                .text {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 20px;
                    color: #2a6e9f;
                    text-align: center;

                    &::after {
                        border-bottom: none;
                    }
                }

                .message {
                    font-size: 12px;
                    font-weight: 500;
                    color: #2a6e9f;
                    text-indent: 2em;
                }


            }

            .right-page {
                width: 100%;
                z-index: 99;
                box-sizing: border-box;
                color: #fff;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                display: flex;
                padding: 0;

                .right-img {
                    width: 100%;
                    height: auto;
                }



            }

        }


    }

}
</style>