<template>
    <div class="research">
        <div class="culture—value">
            <div class="culture—text">
                <div class="text">
                    <div>品牌·电商·视频·手游</div>
                </div>
                <div class="message">
                    天空传媒发展由天空控股集团有限公司控股，作为新媒体内容生态的领航者，自创立以来便深耕于数字娱乐产业的前沿，致力于打造一个集品牌发展、视频创作、直播电商引领、手机小游戏创新于一体的多元化、全方位的数字传媒发展平台。我们凭借敏锐的市场洞察力和卓越的内容创新能力，不断推动行业边界，为亿万用户带来前所未有的传媒体验与消费新场景。  </div>
                <div class="message">
                    天空传媒发展秉持对内容创作的极致追求，发展达人签约经纪业务，深耕构建剧情、vlog、直播、测评等细分赛道的内容生态矩阵；同时扩展入局品牌营销管理赛道，为上广大品牌客户提供数字营销服务。天空传媒发展以“创造无限娱乐价值，连接每一份快乐”为使命，致力于成为数字娱乐领域的创新者与领导者。我们相信，通过不断的技术创新、内容优化与模式探索，能够为用户带来更加丰富多元、便捷高效的传媒娱乐新体验，同时也为合作伙伴创造更大的商业价值与社会价值。在未来的发展中，天空传媒发展将继续秉持开放合作的态度，携手各界伙伴，共同推动产业的繁荣发展。
                </div>
                <!-- <div class="message">
                    同时，研究院为企业提供从技术咨询、方案设计到项目实施、后期维护的全流程技术支持服务。深入了解企业需求，结合行业发展趋势，为企业量身定制技术解决方案，助力企业解决技术难题，提升核心竞争力。
                </div>
                <div class="message">
                    研究院重视科研成果的转化与应用，积极与高校、科研院所以及企业开展产学研合作，共同推动科技成果的商业化、产业化。通过技术转移、技术转让、技术入股等方式，将科技成果转化为现实生产力，为社会经济发展贡献力量。
                </div>
                <div class="message">
                    未来，研究院将继续秉承“创新驱动发展，科技服务未来”的理念，加强研发力量，拓展服务领域，深化产学研合作，为科技事业和企业发展贡献更多力量。期待与更多企业、高校和科研院所建立合作关系，共同推动科技进步和社会发展。
                </div> -->
                <!-- <div class="btn">研究院官网</div> -->
            </div>
            <div class="right-page">
                <el-carousel ref="swiper" :height="`${height}px`" :autoplay="false" :interval="6000"
                    @touchstart="startSwipe" @touchmove="swipe" @touchend="endSwipe">
                    <el-carousel-item v-for="item in imgList" :key="item">
                        <img class="right-img" :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>

            </div>
        </div>

    </div>
</template>

<script>
import img1 from '../../assets/images/business-research-0.png'
export default {
    name: 'ResearchBar',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 580,
            startX: 0,
            endStartX: 0,
            imgList: [
                img1,

            ]
        }
    },
    methods: {
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiper = this.$refs.swiper
            if (this.endStartX - this.startX <= -50) {
                swiper.next()
            }
            if (this.endStartX - this.startX >= 50) {
                swiper.prev()
            }
        },
        getHeight() {
            if (this.isPc()) {
                this.height = 580
                // PC端逻辑
            } else {
                // 手机端逻辑
                this.height = 280
            }
        },
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        }
    },
    mounted() {
        this.getHeight()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.research {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    :deep(.el-carousel .el-carousel__indicators) {
        left: 100%;
        transform: translateX(-110%);
        display: flex;

        .el-carousel__button {
            background-color: #fff;
            opacity: 1;
            border-radius: 0;
            height: 4px;
            width: 60px;
        }

        .is-active .el-carousel__button {
            background-color: #409eff;
        }
    }

    .culture—value {
        width: 1450px;
        min-height: 800px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;
        margin-top: 60px;
        margin-bottom: 60px;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 60px;
        background: url('../../assets/images/service-bg-icon.png') no-repeat;
        background-position: 0px 730px;
        background-size: 600px;
        background-color: #f3f3f3;

        .culture—text {
            flex: 1;
            box-sizing: border-box;
            padding-right: 60px;


            .btn {
                width: 120px;
                height: 40px;
                background-color: #296ea2;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
                margin-top: 80px;
            }

            .text {
                font-size: 33px;
                line-height: 50px;
                margin-bottom: 50px;
                color: #2a6e9f;
                position: relative;

                &::after {
                    content: '';
                    width: 30px;
                    border-bottom: solid 2px #2a6e9f;
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                }
            }

            .message {
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                color: #7c7c7c;
                margin-bottom: 20px;
            }


        }

        .right-page {
            z-index: 99;
            box-sizing: border-box;
            width: 850px;
            position: relative;
            top: 100px;

            .right-img {
                width: 850px;
                height: 580px;
            }

        }

    }



}


@media (max-width: 767px) {
    .research {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        box-sizing: border-box;
        padding-bottom: 10px;
        padding-bottom: 10px;


        .culture—value {
            width: 100%;
            padding: 0px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            display: block;
            min-height: 700px;
            .culture—text {
                width: 100%;
                text-align: center;
                padding: 20px;

                .text {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    color: #2a6e9f;
                    &::after {
                        border-bottom: none;
                    }
                }

                .message {
                    font-size: 12px;
                    font-weight: 500;
                    text-indent: 2em;
                    line-height: 20px;
                    color: #2a6e9f;
                    text-align: left;
                }


            }

            .right-page {
                z-index: 99;
                box-sizing: border-box;
                color: #fff;
                padding: 0;
                width: 100%;
                height: auto;
                top: 0px;

                .right-img {
                    width: 100%;
                    height: 280px;
                }



            }

        }


    }

}
</style>