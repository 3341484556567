<template>
    <div class="research">
        <div class="culture—value">
            <div class="culture—text">
                <div class="text">
                    <div>市政·园林·装饰·安装</div>
                </div>
                <div class="message">
                    天空市政工程(常州)有限公司是天空控股集团有限公司下属的市政建设企业，是一家集园林绿化施工、园林景观设计、绿化养护、苗木生产销售，承接市政工程、城市照明施工、建筑施工及工程维修施工为一体的综合性公司。
                </div>
                <div class="message">
                    公司自成立伊始，就本着“创一流企业，建优质工程，外抓工程质量，内部加强素质管理，让用户满意”的宗旨，不断巩固和发展自身综合实力。经过多次的施工项目实践，锤炼出一支高素质的专业技术队伍，在项目施工过程中坚持“诚信务实”的服务理念，以优秀的服务和过硬的质量为公司打造品牌工程，赢得了各级部门和业主单位的一致好评。
                </div>
                <div class="message">
                    随着经济建设的发展，城市品味的提升，人们越来越重视身边环境的美化，因此公司在政府的关心和支持下得到迅速的发展。未来我们将一如既往地抓住机遇，脚踏实地，以优质诚信的服务为市政事业再作贡献，不断追求创新，为打造生态宜居的城市而不懈努力。

                </div>
                <!-- <div class="btn">市政工程官网</div> -->
            </div>
            <div class="right-page">
                <el-carousel ref="swiper" :height="`${height}px`" :autoplay="false" :interval="6000"
                    @touchstart="startSwipe" @touchmove="swipe" @touchend="endSwipe">
                    <el-carousel-item v-for="item in imgList" :key="item">
                        <img class="right-img" :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>

            </div>
        </div>

    </div>
</template>

<script>
import img1 from '../../assets/images/government-0.png'
export default {
    name: 'ResearchBar',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 580,
            startX: 0,
            endStartX: 0,
            imgList: [
                img1
            ]
        }
    },
    methods: {
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiper = this.$refs.swiper
            if (this.endStartX - this.startX <= -50) {
                swiper.next()
            }
            if (this.endStartX - this.startX >= 50) {
                swiper.prev()
            }
        },
        getHeight() {
            if (this.isPc()) {
                this.height = 580
                // PC端逻辑
            } else {
                // 手机端逻辑
                this.height = 280
            }
        },
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        }
    },
    mounted() {
        this.getHeight()
    },
}
</script>
<style lang="scss" scoped>
.research {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    :deep(.el-carousel .el-carousel__indicators) {
        left: 100%;
        transform: translateX(-110%);
        display: flex;

        .el-carousel__button {
            background-color: #fff;
            opacity: 1;
            border-radius: 0;
            height: 4px;
            width: 60px;
        }

        .is-active .el-carousel__button {
            background-color: #409eff;
        }
    }

    .culture—value {
        width: 1450px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;
        margin-top: 60px;
        margin-bottom: 60px;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 60px;
        background: url('../../assets/images/service-bg-icon.png') no-repeat;
        background-position: 0px 580px;
        background-size: 600px;
        background-color: #f3f3f3;

        .culture—text {
            flex: 1;
            box-sizing: border-box;
            padding-right: 60px;

            .btn {
                width: 120px;
                height: 40px;
                background-color: #296ea2;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
                margin-top: 80px;
            }

            .text {
                font-size: 33px;
                line-height: 50px;
                margin-bottom: 50px;
                color: #2a6e9f;
                position: relative;
                text-align: left;

                &::after {
                    content: '';
                    width: 30px;
                    border-bottom: solid 2px #2a6e9f;
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                }
            }

            .message {
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                color: #7c7c7c;
                margin-bottom: 20px;
                text-indent: 2em;
            }


        }

        .right-page {
            z-index: 99;
            box-sizing: border-box;
            width: 850px;
            position: relative;
            top: 0;

            .right-img {
                width: 850px;
                height: 580px;
            }

        }

    }



}


@media (max-width: 767px) {
    .research {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        box-sizing: border-box;
        padding-bottom: 10px;
        padding-bottom: 10px;


        .culture—value {
            width: 100%;
            padding: 0px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            display: block;

            .culture—text {
                width: 100%;
                text-align: left;
                padding: 20px;

                .btn {
                    margin: 0 auto;
                }

                .text {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    color: #2a6e9f;
                    text-align: center;

                    &::after {
                        border-bottom: none;
                    }
                }

                .message {
                    font-size: 12px;
                    font-weight: 500;
                    text-indent: 2em;
                    line-height: 20px;
                    color: #2a6e9f;
                }


            }

            .right-page {
                z-index: 99;
                box-sizing: border-box;
                color: #fff;
                padding: 0;
                width: 100%;
                height: auto;
                top: 0px;

                .right-img {
                    width: 100%;
                    height: 280px;
                }



            }

        }


    }

}
</style>